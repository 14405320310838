.projects-item {
  padding: 1em 0;
  margin: 1em 0;
  border-top: 1px gray solid;
  display: flex;
  flex-direction: column;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .projects-item-text {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .label-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
    li {
      padding: 0.3em 0.6em;
      border-radius: 0.5em;
      border: 1px solid black;
      font-size: 0.9em;
    }
  }

  .technology-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
    li {
      padding: 0.3em 0.6em;
      border-radius: 0.5em;
      color: white;
      background-color: black;
      font-size: 0.7em;
    }
  }

  .projects-item-image {
    text-align: center;
    margin-bottom: 1em;
    img {
      border: 1px solid black;
    }
  }
}

@media screen and (min-width: 600px) {
  .projects-item {
    flex-direction: row;
    .projects-item-image {
      min-width: 200px;
      margin-right: 1em;
    }

    .projects-item-text {
      margin-left: 0;
    }
  }
}
